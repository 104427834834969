import { SET_USER_PROFILE, CLEAR_USER_DATA, SET_ACS_TOKEN } from "./actions";

const defaultState = {
  profile: null,
  acsToken: "",
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return { ...state, profile: action.profile };
    case CLEAR_USER_DATA:
      return { ...state, profile: null, acsToken: "" };
    case SET_ACS_TOKEN:
      return { ...state, acsToken: action.token };
    default: {
      return state;
    }
  }
};

export { userReducer };
