import React, { useState } from "react";

import VisibilityIcon from "@intouchhealth/components/icons/visibility";
import VisibilityOffIcon from "@intouchhealth/components/icons/visibility_off";
import WarningIcon from "@intouchhealth/components/icons/warning";
import styled, { css } from "styled-components";

import { colors } from "../styles/colors";
import { fontSize, inputErrorMargin, inputErrorStyles } from "../styles/forms";

const StyledPassword = styled.div`
  // This is a temporal fix to avoid bumping error. In the future there might be a helper text that also prevent the bump
  min-height: 6.3125rem;
`;

const StyledInputContainer = styled.div`
  position: relative;
  height: 2.5rem;
  box-sizing: border-box;
  margin-top: 0.3125rem;
  margin-bottom: ${inputErrorMargin};
`;

const StyledIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledWarningIcon = styled(WarningIcon)`
  position: absolute;
  top: 0.375rem;
  right: 2.75rem;
`;

const inputHoverStyles = defaultBorderColor => css`
  border-color: ${props => (props.showError ? colors.warningColor : defaultBorderColor)};
  background-color: ${colors.white};
  box-shadow: 0 2px 12px 0 rgb(32 30 29 / 20%);
`;

const StyledInput = styled.input`
  color: ${colors.black};
  font-size: ${fontSize.medium};
  font-weight: 500;

  width: 100%;
  border: 2px solid ${colors.gray};
  border-radius: 8px;
  padding: 0.5625rem 1rem;
  padding-right: 4.6875rem;
  outline: none;
  box-sizing: border-box;

  ::placeholder {
    color: ${colors.darkGray};
  }

  background-color: ${colors.lightGray};

  ${props =>
    props.showError &&
    css`
      border-color: ${colors.warningColor};
    `}

  &:hover {
    ${inputHoverStyles(colors.darkerGray)}
  }

  &:focus {
    ${inputHoverStyles(colors.lightBlue)}
  }
`;

const StyledLabel = styled.label`
  font-size: ${fontSize.small};
  font-weight: 500;
  color: ${colors.black};
`;

const StyledError = styled.div`
  ${inputErrorStyles}
`;

const iconsSize = "small";

const PasswordInput = ({ field, form: { touched, errors }, label, placeholder }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const isTouchedAndHasError = touched[field.name] && errors[field.name];

  return (
    <StyledPassword>
      <StyledLabel htmlFor={field.name}>{label}</StyledLabel>
      <StyledInputContainer>
        <StyledInput
          type={isPasswordVisible ? "text" : "password"}
          placeholder={placeholder}
          showError={isTouchedAndHasError}
          id={field.name}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
        />
        {isTouchedAndHasError && <StyledWarningIcon color={colors.warningColor} size={iconsSize} />}
        <StyledIconContainer onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
          {isPasswordVisible ? (
            <VisibilityOffIcon color={colors.darkerGray} size={iconsSize} />
          ) : (
            <VisibilityIcon color={colors.darkerGray} size={iconsSize} />
          )}
        </StyledIconContainer>
      </StyledInputContainer>
      {isTouchedAndHasError && <StyledError>{errors[field.name]}</StyledError>}
    </StyledPassword>
  );
};

export default PasswordInput;
