import React, { useEffect, useState } from "react";

import {
  MediaGallery,
  MapToGroupCallProps,
  connectFuncsToContext,
  Header,
  CommandPanelTypes,
  MediaFullScreen,
  MINI_HEADER_WINDOW_WIDTH,
} from "@azure/acs-ui-sdk";
import styled, { css } from "styled-components";

import GroupCallSidebar from "../components/GroupCallSidebar";

const smallScreenWith = `${MINI_HEADER_WINDOW_WIDTH}px`;

const StyledGroupCallPage = styled.div`
  // Don't use 100vh because body has some small margins.
  height: 97vh;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  flex-shrink: 0;
`;

const StyledBody = styled.div`
  flex-grow: 1;
  display: flex;
  max-height: 80vh;
`;

const StyledContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  // This selector targets the MediaGallery container.
  .css-86 {
    height: 100%;
  }

  @media (max-width: ${smallScreenWith}) {
    ${props =>
      props.isPaneOpen &&
      css`
        display: none;
      `}
  }
`;

const StyledSideBar = styled.div`
  flex-shrink: 0;
`;

const StyledScreenShared = styled.div`
  height: 70%;

  #fullScreenStreamMediaId {
    box-sizing: border-box;
  }
`;

const NONE_STATE = "None";

const GroupCallPage = ({ signedIn, screenWidth, screenShareStream, onCallEnd, callState }) => {
  const [selectedPane, setSelectedPane] = useState(CommandPanelTypes.None);
  const [didCallStart, setDidCallStart] = useState(false);
  const isPaneOpen = selectedPane !== CommandPanelTypes.None;

  // The Header needs a callback function but we don't need to do anything here. The useEffect will call onCallEnd.
  const handleCallEnd = () => {};

  // Initial state is None. It changes to different states, and when you leave or someone removes you, it goes back to None.
  // So if callState is None, and it's not the initial state, redirect the user to end call page.
  useEffect(() => {
    if (!didCallStart && callState !== NONE_STATE) {
      setDidCallStart(true);
    }

    if (didCallStart && callState === NONE_STATE) {
      onCallEnd();
    }
  }, [callState, didCallStart, onCallEnd]);

  return (
    <StyledGroupCallPage>
      <StyledHeader>
        <Header
          selectedPane={selectedPane}
          setSelectedPane={setSelectedPane}
          endCallHandler={handleCallEnd}
          screenWidth={screenWidth}
        />
      </StyledHeader>
      <StyledBody>
        <StyledContent isPaneOpen={isPaneOpen}>
          <MediaGallery localVideoScalingMode="Fit" remoteVideoScalingMode="Fit" />
          {screenShareStream && (
            <StyledScreenShared>
              <MediaFullScreen activeScreenShareStream={screenShareStream} />
            </StyledScreenShared>
          )}
        </StyledContent>
        <StyledSideBar>
          <GroupCallSidebar signedIn={signedIn} selectedPane={selectedPane} />
        </StyledSideBar>
      </StyledBody>
    </StyledGroupCallPage>
  );
};

export default connectFuncsToContext(GroupCallPage, MapToGroupCallProps);
