/* 
    @intouchhealth/components uses a ThemeProvider. If we want to include variables inside the theme, 
    we'll need to copy the code from their ThemeProvider in order to customize it.
 */
export const colors = {
  white: "#ffffff",
  lightGray: "#f5f5f5",
  gray: "#ded9d6",
  darkGray: "#ada8a4",
  darkerGray: "#7b746f",
  black: "#302e2c",
  darkerBlack: "#201e1d",
  blue: "#005c95",
  lightBlue: "#00b5e2",
  purple: "#5949A7",

  warningColor: "#f5a719",
};
