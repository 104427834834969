import React from "react";

import styled from "styled-components";

import Header from "../containers/Header";
import { colors } from "../styles/colors";

const StyledTitle = styled.span`
  font-size: 1.75rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
`;

const StyledSubTitle = styled.span`
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
`;

const StyledContainer = styled.div`
  color: ${colors.black};
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85vh;
  background-color: ${colors.lightGray};
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 25rem;
  margin-top: 3.5rem;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.625rem;
  max-width: 25rem;
`;

const CallEndPage = () => (
  <>
    <Header />
    <StyledContainer>
      <StyledTextContainer>
        <StyledTitle>Thank You</StyledTitle>
        <StyledSubTitle>The call with your provider has ended.</StyledSubTitle>
      </StyledTextContainer>
      <StyledImage src="./end-call.png" alt="end call" />
    </StyledContainer>
  </>
);

export default CallEndPage;
