import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
  }

  body {
    margin: 0px;
  }
`;

export default GlobalStyles;
