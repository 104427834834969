/* eslint-disable */

import { SEGMENT_ENABLED } from "./env";

export const initSegment = writeKey => {
  // This is Segment Snippet, formatted with Prettier.
  // The Snippet is from https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart
  !(function () {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console && console.error && console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (t) {
          return function () {
            const e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (let t = 0; t < analytics.methods.length; t++) {
          const e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          const n = document.createElement("script");
          n.type = "text/javascript";
          n.async = !0;
          n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
          const a = document.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = "4.1.0";
        analytics.load(writeKey);
      }
  })();
};

const isSegmentAvailable = () => {
  return SEGMENT_ENABLED && window.analytics;
};

export const recordPage = () => {
  if (isSegmentAvailable()) {
    window.analytics.page();
  }
};

export const identifyUser = userInfo => {
  if (isSegmentAvailable()) {
    window.analytics.identify(userInfo);
  }
};
