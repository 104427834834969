import React from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowRightIcon } from "@fluentui/react-icons-northstar";
import { Button as IthButton } from "@intouchhealth/components";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { routesPaths } from "../routers/routesPaths";
import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";

const StyledPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 92vh;
`;

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
    padding: 2rem;
    text-align: left;
  }
`;

const StyledImage = styled.img`
  margin-top: 5.3125rem;
  max-width: 34.375rem;
  width: 100%;
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 0;
    margin-left: 2.3125rem;
  }
`;

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.xl}) {
    align-items: flex-start;
  }
`;

const StyledH1 = styled.h1`
  color: ${colors.black};
  letter-spacing: 0;
  margin: 0;
  max-width: 34.3125rem;
  @media (min-width: ${breakpoints.md}) {
    font-size: 3.5rem;
    line-height: 4rem;
  }
`;

const StyledParagraph = styled.p`
  color: ${colors.darkerGray};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
  max-width: 28.1875rem;
  min-height: 4.5rem;
`;

const StyledIthButton = styled(IthButton)`
  background-color: ${colors.purple};
  color: ${colors.white};
  width: 17rem;
`;

const StyledFooter = styled.div`
  align-items: center;
  color: ${colors.darkerGray};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin-bottom: 1.625rem;
  margin-top: 13.6875rem;
  padding: 0 1rem;
  text-align: center;
  width: 19.0625rem;
  @media (min-width: ${breakpoints.xl}) {
    display: block;
    margin-top: 0px;
    width: 100%;
  }
`;

const StyledFooterButton = styled(IthButton)`
  border: 2px solid ${colors.blue};
  color: ${colors.blue};
  font-size: 0.875rem;
  height: 2rem;
  margin-top: 1rem;
  white-space: nowrap;
  width: 9.5rem;
  &:hover {
    color: ${colors.blue};
    /* this is to remove the gray background */
    &::after {
      background: transparent;
    }
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-left: 1rem;
  }
`;

const LandingPage = () => (
  <div data-testid="landing-page-component">
    <StyledPage>
      <StyledContent>
        <StyledContainer>
          <StyledH1>The telemedicine solution for private practices</StyledH1>
          <StyledParagraph>
            We make it easy for you to meet with your patients. Create an account, get a link, share
            it with your patients. It’s that easy.
          </StyledParagraph>
          <Link to={routesPaths.signup}>
            <StyledIthButton display="default" type="button" size="medium">
              Request Early Access
            </StyledIthButton>
          </Link>
        </StyledContainer>
        <StyledImage src="physician.png" alt="Telemedicine" />
      </StyledContent>
      <StyledFooter>
        <span>We’re building this app for you. Let us know what you want to see.</span>
        <a href="https://www.physicianpractice.app/">
          <StyledFooterButton
            display="default"
            size="small"
            type="button"
            icon={ArrowRightIcon}
            isTextAlignLeft
            isSubtle
          >
            Find Out How
          </StyledFooterButton>
        </a>
      </StyledFooter>
    </StyledPage>
  </div>
);

export default LandingPage;
