import React from "react";

import { FormItem as ImportedFormItem } from "@intouchhealth/components/forms/formItem";
import { TextInput as ImportedTextInput } from "@intouchhealth/components/forms/textInput";
import styled from "styled-components";

import { colors } from "../styles/colors";
import { inputErrorMargin } from "../styles/forms";

const StyledFormItem = styled(ImportedFormItem)`
  margin-bottom: 0.875rem;

  // If we decide to keep these styles, maybe we should build the component ourselves.
  input {
    background-color: ${colors.lightGray};
  }

  label {
    color: ${colors.black};
  }

  // This selector targets the error message div.
  label + div {
    color: ${colors.black};
  }

  // This selector targets the input div.
  label > div:nth-child(2) {
    margin-bottom: ${inputErrorMargin};
  }
`;

const TextInput = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  placeholder,
  showErrorMessage,
  validations = {},
}) => {
  const isTouchedAndHasError = touched[name] && errors[name];
  const maxLength = validations[name]?.maxLength;

  return (
    <StyledFormItem
      label={label}
      helper={showErrorMessage && isTouchedAndHasError ? errors[name] : " "}
      validation={isTouchedAndHasError ? "warning" : "default"}
    >
      <ImportedTextInput
        onChange={event => {
          // We need to use setFieldValue instead of passing down field.onChange prop because <TextInput> doesn't allow to set the name of the <input>.
          const newValue = event.target.value;

          if (!maxLength || newValue.length <= maxLength) {
            setFieldValue(name, event.target.value);
          }
        }}
        onBlur={() => {
          // Same as before with handleBlur.
          setFieldTouched(name);
        }}
        value={value}
        placeholder={placeholder}
        size="medium"
      />
    </StyledFormItem>
  );
};

export default TextInput;
