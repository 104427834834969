import React, { useState, useEffect } from "react";

import { CallingProvider, CallProvider } from "@azure/acs-ui-sdk";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Spinner } from "@fluentui/react";
import qs from "qs";
import { connect } from "react-redux";
import styled from "styled-components";

import { getAcsToken } from "../api/v1/users";
import CallEndPage from "../components/CallEndPage";
import { routesPaths } from "../routers/routesPaths";
import { setAcsToken } from "../store/user/actions";
import ConfigurationPage from "./ConfigurationPage";
import GroupCallPage from "./GroupCallPage";

const StyledSpinnerContainer = styled.div`
  margin-top: 6rem;
`;

const displayNameMaxLength = 25;

const callPages = {
  configuration: "configuration",
  groupCall: "groupCall",
  endCall: "endCall",
};

const getTokenAndUpdateStore = dispatch => {
  return getAcsToken().then(
    newToken => {
      dispatch(setAcsToken(newToken));
      return newToken;
    },
    () => {
      console.log("Failed to get ACS token from backend");
      return "";
    },
  );
};

const CallPage = ({ dispatch, location, history, signedIn, displayName, acsTokenFromStore }) => {
  const [screenWidth, setScreenWidth] = useState(0);
  const [page, setPage] = useState(callPages.configuration);
  const [groupId, setGroupId] = useState("");
  const [token, setToken] = useState("");
  const switchToCallGroupPage = () => setPage(callPages.groupCall);

  const switchToEndCallPage = () => {
    if (signedIn) {
      // It's a provider.
      history.push(routesPaths.home);
    } else {
      // It's a patient.
      setPage(callPages.endCall);
    }
  };

  const refreshTokenCallback = () => {
    // Update the store for next time and return the new token to use it now.
    return getTokenAndUpdateStore(dispatch);
  };

  useEffect(() => {
    if (!acsTokenFromStore) {
      getTokenAndUpdateStore(dispatch);
    }
  }, [acsTokenFromStore, dispatch]);

  useEffect(() => {
    // This avoids re-rendering if the token in the store expires and we get a new one (re-render makes CallingProvider fail).
    // In this situation, ACS will get the token from the promise, so it doesn't need to get the new one from the store. We update the store for next time.
    if (acsTokenFromStore && !token) {
      setToken(acsTokenFromStore);
    }
  }, [acsTokenFromStore, token]);

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const groupIdParam = queryParams.groupId;
    if (groupIdParam) {
      setGroupId(groupIdParam);
    } else {
      history.push(routesPaths.home);
    }
  }, [location, history]);

  useEffect(() => {
    const setWindowWidth = () => {
      const width = window ? window.innerWidth : 0;
      setScreenWidth(width);
    };
    setWindowWidth();
    window.addEventListener("resize", setWindowWidth);
    return () => window.removeEventListener("resize", setWindowWidth);
  }, []);

  const getCurrentCallPage = () => {
    if (page === callPages.configuration) {
      return (
        <ConfigurationPage
          displayNameMaxLength={displayNameMaxLength}
          onStartCallClick={switchToCallGroupPage}
        />
      );
    }

    if (page === callPages.groupCall) {
      return (
        <GroupCallPage
          signedIn={signedIn}
          screenWidth={screenWidth}
          onCallEnd={switchToEndCallPage}
        />
      );
    }

    return <CallEndPage />;
  };

  return (
    <div>
      {groupId && token ? (
        <CallingProvider
          groupId={groupId}
          displayName={displayName}
          token={token}
          refreshTokenCallback={refreshTokenCallback}
        >
          <CallProvider>{getCurrentCallPage()}</CallProvider>
        </CallingProvider>
      ) : (
        <StyledSpinnerContainer>
          <Spinner label="Initializing" />
        </StyledSpinnerContainer>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { profile, acsToken } = state.user;
  let displayName = "";
  let signedIn = false;
  if (profile) {
    signedIn = true;
    displayName = `${profile.first_name} ${profile.last_name}`.substring(0, displayNameMaxLength);
  }

  return {
    signedIn,
    displayName,
    acsTokenFromStore: acsToken,
  };
};

export default connect(mapStateToProps)(CallPage);
