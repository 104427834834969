import { css } from "styled-components";

import { colors } from "./colors";

export const fontSize = {
  small: "0.75rem",
  medium: "1rem",
};

export const inputErrorStyles = css`
  color: ${colors.black};
  font-size: ${fontSize.small};
  font-weight: 500;
`;

export const inputErrorMargin = "0.4375rem";
