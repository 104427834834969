import React from "react";

import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import Dashboard from "../components/Dashboard";
import LandingPage from "../components/LandingPage";
import NotFoundPage from "../components/NotFoundPage";
import CallPage from "../containers/CallPage";
import SignInCallback from "../containers/SignInCallback";
import SignOutCallback from "../containers/SignOutCallback";
import SignUpPage from "../containers/SignUpPage";
import { IDP_REDIRECT_PATH, IDP_POST_LOGOUT_REDIRECT_PATH } from "../util/env";
import { routesPaths } from "./routesPaths";

const AppRouter = ({ signedIn }) => {
  if (signedIn) {
    return (
      <Switch>
        <Route exact path={routesPaths.home} component={Dashboard} />
        <Route path={IDP_POST_LOGOUT_REDIRECT_PATH} component={SignOutCallback} />
        <Route path={routesPaths.signup}>
          <Redirect push to={routesPaths.home} />
        </Route>
        <Route path={routesPaths.call} component={CallPage} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route exact path={routesPaths.home} component={LandingPage} />
      <Route path={IDP_REDIRECT_PATH} component={SignInCallback} />
      <Route path={IDP_POST_LOGOUT_REDIRECT_PATH} component={SignOutCallback} />
      <Route path={routesPaths.signup} component={SignUpPage} />
      <Route path={routesPaths.call} component={CallPage} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

AppRouter.propTypes = {
  signedIn: PropTypes.bool.isRequired,
};

export default AppRouter;
