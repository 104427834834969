import React from "react";

import {
  CommandPanelTypes,
  ParticipantStack,
  LocalSettings,
  ParticipantStackItemComponent,
} from "@azure/acs-ui-sdk";
import Footer from "@azure/acs-ui-sdk/dist/components/Footer";
import styled from "styled-components";

const panePadding = "1.25rem";

const StyledPane = styled.div`
  width: 18.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  padding: ${panePadding};
  flex-shrink: 0;
`;

const StyledParticipantStack = styled.div`
  flex-grow: 1;

  // Hack to avoid creating a new child component that allows longer display names. In the future we can use onRenderParticipant prop from ParticipantStack.
  .ms-Persona-details {
    padding-right: 50px;
  }
`;

const StyledFooter = styled.div`
  flex-shrink: 0;
`;

const StyledLocalSettings = styled.div`
  padding-left: ${panePadding};
  padding-right: ${panePadding};
`;

// Render ParticipantStackItem without onRemove and onMute callbacks.
// Physicians will use the default ParticipantStackItem that let them remove other participants, not this one.
const renderParticipant = ({ displayName, state, isScreenSharing, isMuted }) => {
  return (
    <ParticipantStackItemComponent
      name={displayName}
      state={state}
      isScreenSharing={isScreenSharing}
      isMuted={isMuted}
    />
  );
};

const GroupCallSidebar = ({ signedIn, selectedPane }) =>
  selectedPane !== CommandPanelTypes.None && (
    <StyledPane>
      <StyledTitle>{selectedPane}</StyledTitle>
      {selectedPane === CommandPanelTypes.People && (
        <>
          <StyledParticipantStack>
            <ParticipantStack onRenderParticipant={signedIn ? undefined : renderParticipant} />
          </StyledParticipantStack>
          <StyledFooter>
            <Footer />
          </StyledFooter>
        </>
      )}
      {selectedPane === CommandPanelTypes.Settings && (
        <StyledLocalSettings>
          <LocalSettings />
        </StyledLocalSettings>
      )}
    </StyledPane>
  );

export default GroupCallSidebar;
