import React from "react";

import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { configureAxios } from "./api/configureAxios";
import UnhandledError from "./components/UnhandledError";
import App from "./containers/App";
import reportWebVitals from "./reportWebVitals";
import { history } from "./routers/history";
import { configureStore } from "./store/configureStore";
import ThemeProvider from "./ThemeProvider";
import {
  SENTRY_ENABLED,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
  SEGMENT_ENABLED,
  SEGMENT_WRITE_KEY,
} from "./util/env";
import { initSegment } from "./util/segmentAnalytics";

const store = configureStore();
configureAxios(store);

if (SENTRY_ENABLED) {
  const sentryConfig = {
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
  };

  if (SENTRY_RELEASE) {
    sentryConfig.release = SENTRY_RELEASE;
  }

  Sentry.init(sentryConfig);
}

if (SEGMENT_ENABLED) {
  initSegment(SEGMENT_WRITE_KEY);
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={UnhandledError}>
      <ThemeProvider>
        <Provider store={store}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
