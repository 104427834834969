import React from "react";

import { Button as IthButton } from "@intouchhealth/components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { routesPaths } from "../routers/routesPaths";
import { colors } from "../styles/colors";

const StyledHeader = styled.header`
  background-color: ${colors.white};
  padding: 1.4375rem 1rem;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const StyledLogoText = styled.span`
  margin-left: 1rem;
  color: ${colors.black};
  font-size: 1.125rem;
  font-weight: 500;
`;

const StyledLogoImage = styled.img`
  width: 1.5625rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: currentColor;
`;

const StyledButtonContainer = styled.div`
  display: flex;
`;

const StyledIthButton = styled(IthButton)`
  white-space: nowrap;
  color: ${colors.blue};
  &:hover {
    color: ${colors.blue};
  }
`;

const HeaderContent = ({ signedIn, signInUrl, signOutUrl, withButtons }) => (
  <StyledHeader>
    <StyledLogoContainer>
      <StyledLogoLink to={routesPaths.home}>
        <StyledLogoImage src="teladoc-logo.svg" alt="Teladoc Logo" />
        <StyledLogoText>Physician Practice</StyledLogoText>
      </StyledLogoLink>
      {withButtons && (
        <StyledButtonContainer>
          {signedIn ? (
            <StyledLink href={signOutUrl}>
              <StyledIthButton isSubtle size="small">
                Sign out
              </StyledIthButton>
            </StyledLink>
          ) : (
            <>
              <StyledLink href={signInUrl}>
                <StyledIthButton isSubtle size="small">
                  Sign In
                </StyledIthButton>
              </StyledLink>
            </>
          )}
        </StyledButtonContainer>
      )}
    </StyledLogoContainer>
  </StyledHeader>
);

HeaderContent.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  signInUrl: PropTypes.string.isRequired,
  signOutUrl: PropTypes.string.isRequired,
};

export default HeaderContent;
