import React from "react";

import { Button } from "@intouchhealth/components";
import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";

import { postSignUp, postSignUpErrors } from "../api/v1/users";
import { fontSize } from "../styles/forms";
import Checkbox from "./Checkbox";
import PasswordInput from "./PasswordInput";
import TextInput from "./TextInput";

const StyledSignUpButton = styled(Button)`
  height: 3rem;
  font-size: ${fontSize.medium};
  margin-top: 3rem;
`;

const StyledFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledCheckboxRow = styled.div`
  margin-top: 0.9375rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
  margin-left: 0.25rem;
  font-size: ${fontSize.medium};
`;

const StyledSmallInput = styled.div`
  width: 100%;

  @media (min-width: 480px) {
    width: 50%;
    max-width: 13.625rem;
  }
`;

const privacyPolicyLink = "https://intouchhealth.com/privacy-policy/";

export const errorMessages = {
  required: "Required",
  invalid: "Invalid",
  invalidEmail: "Enter valid e-mail.",
  alreadyRegisteredEmail: "Email has already been taken.",
  passwordRequirements:
    "Needs at least one number, one lowercase, one uppercase and one special character.",
  passwordIncludesNames: "Password cannot include first or last name.",
  getMinLengthError: minLength => `At least ${minLength} characters.`,
};

export const labels = {
  practiceName: "Practice Name",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  password: "Password",
  agreedTerms: "I agree to the",
};

const initialValues = {
  practiceName: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  agreedTerms: false,
};

// All inputs are required.
const lengthValidations = {
  practiceName: { minLength: 2, maxLength: 64 },
  firstName: { minLength: 2, maxLength: 100 },
  lastName: { minLength: 2, maxLength: 100 },
  email: { maxLength: 254 },
  password: { minLength: 8, maxLength: 50 },
  agreedTerms: {},
};

let previousRegisteredEmail = null;

const getEmailError = email => {
  if (!email) return errorMessages.required;

  if (previousRegisteredEmail && email === previousRegisteredEmail)
    return errorMessages.alreadyRegisteredEmail;

  // i flag means upper/lower case differences will be ignored.
  // (?!\.)   Can't start with a .
  // (?!.*(\.\.))   Can't have 2 consecutive .
  // (?!.*(\.)$)  Can't end with .
  // (?!.*(\.@))  Local part can't end with .
  // (?!.*(@\.))  Domain part can't start with .
  const emailRegEx = /^(?!\.)(?!.*(\.\.))(?!.*(\.)$)(?!.*(\.@))(?!.*(@\.))[A-Z0-9.!#$%&’*+/=?^_`{|}~-]{1,64}@[A-Z0-9.-]{2,189}$/i;
  if (!emailRegEx.test(email)) return errorMessages.invalidEmail;

  return undefined;
};

const getRequiredAndLengthErrors = (value, name) => {
  if (!value) return errorMessages.required;

  const { minLength } = lengthValidations[name];
  if (minLength && value.length < minLength) {
    return errorMessages.getMinLengthError(minLength);
  }

  return undefined;
};

const getNameFieldError = (value, key) => {
  const lengthError = getRequiredAndLengthErrors(value, key);
  if (lengthError) return lengthError;

  const nameRegEx = /^(?! )(?!.*( ){2})(?!.*( )$)[a-zA-ZÀ-ž' +.-]*$/i;
  if (!nameRegEx.test(value)) return errorMessages.invalid;

  return undefined;
};

const getPasswordError = (value, key) => {
  const lengthError = getRequiredAndLengthErrors(value, key);
  if (lengthError) return lengthError;

  // (?=.*\d) # At least one number
  // (?=.*[a-z]) # At least one lowercase letter
  // (?=.*[A-Z]) # At least one uppercase letter
  // (?=.*\W) # At least one symbol
  const passwordRegEx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
  if (!passwordRegEx.test(value)) return errorMessages.passwordRequirements;

  return undefined;
};

const getFormErrors = values => {
  const errors = {};
  const { password, firstName, lastName } = values;

  if (password && firstName && lastName) {
    const parsedPassword = password.toLowerCase();
    const parsedFirstName = firstName.toLowerCase();
    const parsedLastName = lastName.toLowerCase();

    if (parsedPassword.includes(parsedFirstName) || parsedPassword.includes(parsedLastName)) {
      errors.password = errorMessages.passwordIncludesNames;
    }
  }

  return errors;
};

const SignUpForm = ({ onSignUpSuccessful, onUnrecoverableError }) => {
  const handleSubmit = (values, formikActions) => {
    postSignUp(values)
      .then(response => {
        formikActions.setSubmitting(false);
        onSignUpSuccessful(response.data);
      })
      .catch(error => {
        formikActions.setSubmitting(false);
        if (error.response.status === postSignUpErrors.EMAIL_ALREADY_REGISTERED) {
          previousRegisteredEmail = values.email;

          formikActions.setFieldError("email", errorMessages.alreadyRegisteredEmail);
        } else {
          onUnrecoverableError(error);
        }
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={getFormErrors}>
      {props => (
        <Form>
          <Field
            name="practiceName"
            label={labels.practiceName}
            showErrorMessage
            validations={lengthValidations}
            component={TextInput}
            validate={value => getRequiredAndLengthErrors(value, "practiceName")}
          />
          <StyledFormRow>
            <StyledSmallInput>
              <Field
                name="firstName"
                label={labels.firstName}
                showErrorMessage
                validations={lengthValidations}
                validate={value => getNameFieldError(value, "firstName")}
                component={TextInput}
              />
            </StyledSmallInput>
            <StyledSmallInput>
              <Field
                name="lastName"
                label={labels.lastName}
                showErrorMessage
                validations={lengthValidations}
                validate={value => getNameFieldError(value, "lastName")}
                component={TextInput}
              />
            </StyledSmallInput>
          </StyledFormRow>
          <Field
            name="email"
            label={labels.email}
            showErrorMessage
            validations={lengthValidations}
            validate={getEmailError}
            component={TextInput}
          />
          <Field
            name="password"
            label={labels.password}
            validate={value => getPasswordError(value, "password")}
            component={PasswordInput}
          />
          <StyledCheckboxRow>
            <Field
              name="agreedTerms"
              label={labels.agreedTerms}
              labelLink={
                <StyledLink href={privacyPolicyLink} target="_blank">
                  Privacy Policy.
                </StyledLink>
              }
              validate={value => getRequiredAndLengthErrors(value, "agreedTerms")}
              component={Checkbox}
            />
          </StyledCheckboxRow>
          <StyledSignUpButton
            display="primary"
            size="small"
            type="submit"
            isDisabled={props.isSubmitting}
            isFullWidth
          >
            Sign up
          </StyledSignUpButton>
        </Form>
      )}
    </Formik>
  );
};

SignUpForm.propTypes = {
  onSignUpSuccessful: PropTypes.func.isRequired,
  onUnrecoverableError: PropTypes.func.isRequired,
};

export default SignUpForm;
