import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUserProfile } from "../api/v1/users";
import AppRouter from "../routers/AppRouter";
import { history } from "../routers/history";
import { routesPaths } from "../routers/routesPaths";
import { setUserProfile } from "../store/user/actions";
import { recordPage } from "../util/segmentAnalytics";
import { backupUserProfile, USER_PROFILE_KEY } from "../util/user";
import Header from "./Header";

const pathsWithoutHeader = [routesPaths.signup, routesPaths.call];

const shouldRenderHeader = pathName => {
  return !pathsWithoutHeader.includes(pathName);
};

const App = ({ signedIn, dispatch }) => {
  const location = useLocation();

  useEffect(() => {
    const userProfile = JSON.parse(sessionStorage.getItem(USER_PROFILE_KEY));
    if (userProfile) {
      // This will execute on a page refresh.
      dispatch(setUserProfile(userProfile));
    } else {
      // This will execute on opening a new tab or window.
      getUserProfile()
        .then(response => {
          dispatch(setUserProfile(response.data));
          backupUserProfile(response.data);
        })
        // Silently fail to get user profile from backend.
        .catch(() => {});
    }
  }, [dispatch]);

  useEffect(() => {
    recordPage();

    const unlisten = history.listen(() => {
      recordPage();
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      {shouldRenderHeader(location.pathname) && <Header withButtons />}
      <AppRouter signedIn={signedIn} />
    </>
  );
};

const mapStateToProps = state => ({
  signedIn: !!state.user.profile,
});

App.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(App);
