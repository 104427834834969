import React from "react";

import { connect } from "react-redux";

import HeaderContent from "../components/HeaderContent";
import { buildSignInUrl, buildSignOutUrl } from "../util/auth";
import { IDP_POST_LOGOUT_REDIRECT_PATH } from "../util/env";

const Header = ({ signedIn, idToken, lastName, firstName, withButtons }) => {
  // Sign up users don't have idToken.
  const signOutUrl = idToken ? buildSignOutUrl(idToken) : IDP_POST_LOGOUT_REDIRECT_PATH;

  return (
    <div data-testid="header-container">
      <HeaderContent
        signedIn={signedIn}
        signInUrl={buildSignInUrl()}
        signOutUrl={signOutUrl}
        withButtons={withButtons}
      />
    </div>
  );
};

const mapStateToProps = state => {
  if (state.user.profile) {
    return {
      signedIn: true,
      idToken: state.user.profile.id_token,
      lastName: state.user.profile.last_name,
      firstName: state.user.profile.first_name,
    };
  }
  return {
    signedIn: false,
    idToken: null,
    lastName: null,
    firstName: null,
  };
};

export default connect(mapStateToProps)(Header);
