import {
  PUBLIC_URL,
  IDP_BASE_URL,
  IDP_CLIENT_ID,
  IDP_AUTHORIZE_PATH,
  IDP_LOGOUT_PATH,
  IDP_REDIRECT_PATH,
  IDP_POST_LOGOUT_REDIRECT_PATH,
  IDP_SCOPE,
} from "./env";

const NONCE_PARAM = "NONCE";

const STATE_PARAM = "STATE";

const getOrSetUniqueString = key => {
  let value = sessionStorage.getItem(key);
  if (!value) {
    value = Date.now().toString(36) + Math.random().toString(36).substring(2);
    sessionStorage.setItem(key, value);
  }

  return value;
};

const buildSignInUrl = () => {
  const redirectUrl = new URL(IDP_REDIRECT_PATH, PUBLIC_URL);
  const url = new URL(IDP_AUTHORIZE_PATH, IDP_BASE_URL);
  url.searchParams.append("response_type", "code");
  url.searchParams.append("client_id", IDP_CLIENT_ID);
  url.searchParams.append("redirect_uri", redirectUrl);
  url.searchParams.append("scope", IDP_SCOPE);
  url.searchParams.append("nonce", getOrSetUniqueString(NONCE_PARAM));
  url.searchParams.append("state", getOrSetUniqueString(STATE_PARAM));

  return url.href;
};

const buildSignOutUrl = idToken => {
  if (!idToken) return undefined;

  const postLogoutRedirectUrl = new URL(IDP_POST_LOGOUT_REDIRECT_PATH, PUBLIC_URL);
  const url = new URL(IDP_LOGOUT_PATH, IDP_BASE_URL);
  url.searchParams.append("id_token_hint", idToken);
  url.searchParams.append("post_logout_redirect_uri", postLogoutRedirectUrl);

  return url.href;
};

export { NONCE_PARAM, STATE_PARAM, buildSignInUrl, buildSignOutUrl };
