import { createStore, combineReducers } from "redux";

import { userReducer } from "./user/reducer";

const reducers = {
  user: userReducer,
};

const configureStore = () => {
  const store = createStore(
    combineReducers(reducers),
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );

  return store;
};

export { configureStore };
