import axios from "axios";

import { buildSignOutUrl } from "../util/auth";

const configureAxios = store => {
  // Mitigate CSRF attacks.
  // https://pragmaticstudio.com/tutorials/rails-session-cookies-for-api-authentication
  axios.defaults.xsrfCookieName = "CSRF-TOKEN";
  axios.defaults.xsrfHeaderName = "X-CSRF-Token";
  axios.defaults.withCredentials = true;

  // Sign out if any request receives a 401.
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        try {
          const idToken = store.getState().user.profile.id_token;
          window.location.href = buildSignOutUrl(idToken);
          // eslint-disable-next-line no-empty
        } catch {}
      }

      throw error;
    },
  );
};

export { configureAxios };
