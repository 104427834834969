/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";

import {
  DisplayNameField,
  LocalSettings,
  LocalPreview,
  MapToCallConfigurationProps,
  connectFuncsToContext,
} from "@azure/acs-ui-sdk";
import { Spinner } from "@fluentui/react";
import { CallVideoIcon } from "@fluentui/react-icons-northstar";
import { Button as IthButton } from "@intouchhealth/components";
import styled from "styled-components";

import { colors } from "../styles/colors";

const StyledConfiguration = styled.div`
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledConfigurationFields = styled.div`
  flex-shrink: 0;
  margin-left: 3rem;

  @media (max-width: 760px) {
    margin-top: 1.5rem;
    margin-left: 0;
  }
`;

const StyledButtonContainer = styled.div`
  margin-top: 1.5rem;
`;

const StyledIthButton = styled(IthButton)`
  width: 100%;
  background-color: ${colors.purple};
`;

const ConfigurationPage = props => {
  const {
    displayNameMaxLength,
    displayName,
    isCallInitialized,
    updateDisplayName,
    joinCall,
    onStartCallClick,
  } = props;

  const [emptyWarning, setEmptyWarning] = useState(false);

  // We need to pass a function as a prop, but we won't use the length limit that they have.
  const setIsNameLengthExceedLimit = () => {};

  const handleStartCallClick = () => {
    if (!displayName) {
      setEmptyWarning(true);
    } else {
      joinCall();
      onStartCallClick();
    }
  };

  const handleDisplayNameChange = newDisplayName => {
    let parsedName = newDisplayName;
    if (newDisplayName.length > displayNameMaxLength) {
      parsedName = newDisplayName.substring(0, displayNameMaxLength);
    }
    updateDisplayName(parsedName);
  };

  return (
    <StyledConfiguration>
      <LocalPreview />
      <StyledConfigurationFields>
        <DisplayNameField
          defaultName={displayName}
          isEmpty={emptyWarning}
          isNameLengthExceedLimit={false}
          setName={handleDisplayNameChange}
          setEmptyWarning={setEmptyWarning}
          setNameLengthExceedLimit={setIsNameLengthExceedLimit}
        />
        <LocalSettings />
        <StyledButtonContainer>
          {isCallInitialized ? (
            <StyledIthButton
              display="default"
              size="small"
              type="button"
              isTextAlignLeft
              isFullWidth
              icon={CallVideoIcon}
              onClick={handleStartCallClick}
            >
              Enter Exam Room
            </StyledIthButton>
          ) : (
            <Spinner label="Loading" labelPosition="right" />
          )}
        </StyledButtonContainer>
      </StyledConfigurationFields>
    </StyledConfiguration>
  );
};

export default connectFuncsToContext(ConfigurationPage, MapToCallConfigurationProps);
