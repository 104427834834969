import React from "react";

import { Checkbox as ImportedCheckbox } from "@intouchhealth/components";
import styled, { css } from "styled-components";

import { colors } from "../styles/colors";
import { fontSize, inputErrorMargin, inputErrorStyles } from "../styles/forms";

const StyledCheckbox = styled(ImportedCheckbox)`
  margin-left: 0rem;
  font-size: ${fontSize.medium};

  // This selector targets the custom div that represent the checkbox.
  input + div {
    flex-shrink: 0;
  }

  ${props =>
    props.hasError &&
    css`
      input + div {
        border-color: ${colors.warningColor};
      }
    `}
`;

const StyledCheckboxRow = styled.div`
  display: flex;

  margin-bottom: ${props => (props.hasError ? "0rem" : "1.375rem")};
`;

const StyledError = styled.div`
  ${inputErrorStyles}
  margin-top: ${inputErrorMargin};
`;

// setFieldValue will trigger the validation. It doesn't need to run again.
const touchedTrue = true;
const validateAfterTouched = false;

const Checkbox = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  labelLink,
}) => {
  const isTouchedAndHasError = touched[name] && errors[name];

  return (
    <>
      <StyledCheckboxRow hasError={isTouchedAndHasError}>
        <StyledCheckbox
          labelColor={colors.darkerBlack}
          label={label}
          onChange={event => {
            setFieldValue(name, event.target.checked);
            setFieldTouched(name, touchedTrue, validateAfterTouched);
          }}
          isChecked={value}
          hasError={isTouchedAndHasError}
        />
        {labelLink}
      </StyledCheckboxRow>
      {isTouchedAndHasError && <StyledError>{errors[name]}</StyledError>}
    </>
  );
};

export default Checkbox;
