import React from "react";

import styled from "styled-components";
import { v1 as createGUID } from "uuid";

import { colors } from "../styles/colors";
import DashboardContent from "./DashboardContent";

const StyledDashboard = styled.div`
  background-color: ${colors.lightGray};
`;

const groupId = createGUID();

const Dashboard = () => (
  <StyledDashboard data-testid="dashboard-container">
    <DashboardContent groupId={groupId} />
  </StyledDashboard>
);

export default Dashboard;
