import React, { useEffect } from "react";

import { connect } from "react-redux";

import { postSignOut } from "../api/v1/auth";
import LandingPage from "../components/LandingPage";
import { clearUserData } from "../store/user/actions";
import { backupUserProfile } from "../util/user";

const SignOutCallback = ({ dispatch, history }) => {
  useEffect(() => {
    // This request will silently fail if there is not a valid session cookie.
    // TODO: Consider adding a .catch() to this that renders a message telling
    // the user they have been signed out.
    postSignOut().finally(() => {
      dispatch(clearUserData());
      backupUserProfile(null);
      history.push("/");
    });
  }, [history, dispatch]);

  return <LandingPage />;
};

export default connect()(SignOutCallback);
