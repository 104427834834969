import axios from "axios";

const baseUsersUrl = "/api/v1/users";

const postSignUpErrors = {
  EMAIL_ALREADY_REGISTERED: 409,
};

const getUserProfile = async () => axios.get(`${baseUsersUrl}/profile`);

const getAcsToken = async () => {
  return axios.get(`${baseUsersUrl}/acs_token`).then(response => response.data.token);
};

const postSignUp = formValues => {
  const postBody = {
    practice_name: formValues.practiceName,
    first_name: formValues.firstName,
    last_name: formValues.lastName,
    email: formValues.email,
    password: formValues.password,
  };

  return axios.post(`${baseUsersUrl}/sign_up`, postBody);
};

export { getUserProfile, getAcsToken, postSignUp, postSignUpErrors };
