import React from "react";

import { captureException } from "@sentry/react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import SignUpForm from "../components/SignUpForm";
import { history } from "../routers/history";
import { routesPaths } from "../routers/routesPaths";
import { setUserProfile } from "../store/user/actions";
import { colors } from "../styles/colors";
import { fontSize } from "../styles/forms";
import { buildSignInUrl } from "../util/auth";
import { backupUserProfile } from "../util/user";

const StyledSignUpContainer = styled.div`
  width: 100%;
  max-width: 28.25rem;
  margin-top: 5.25rem;
  margin-left: auto;
  margin-right: auto;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const StyledLogoText = styled.span`
  margin-left: 0.9375rem;
  color: ${colors.black};
  font-size: 2.25rem;
  font-weight: 500;
`;

const StyledLogoImage = styled.img`
  width: 3.0625rem;
`;

const StyledPageTitle = styled.h1`
  text-align: center;
  color: ${colors.black};
  font-size: 1.75rem;
  font-weight: 500;
  margin-top: 2.4375rem;
  margin-bottom: 2rem;
`;

const StyledSignInText = styled.p`
  text-align: center;
  color: ${colors.darkerBlack};
  font-size: ${fontSize.medium};
  font-weight: 500;
  margin-top: 1.5rem;
`;

const StyledSignInLink = styled.a`
  text-decoration: none;
`;

const signInUrl = buildSignInUrl();

const handleUnrecoverableError = axiosError => {
  const sentryContext = {
    tags: {
      customEvent: "SignUp Error",
    },
  };

  const errorData = axiosError.toJSON();

  captureException(errorData, sentryContext);
};

const SignUpPage = ({ dispatch }) => {
  const handleSignUpSuccessful = userProfile => {
    dispatch(setUserProfile(userProfile));
    backupUserProfile(userProfile);
    history.push(routesPaths.home);
  };

  return (
    <StyledSignUpContainer>
      <StyledLogoContainer>
        <StyledLogoLink to={routesPaths.home}>
          <StyledLogoImage src="teladoc-logo.svg" alt="Teladoc Logo" />
          <StyledLogoText>SPP</StyledLogoText>
        </StyledLogoLink>
      </StyledLogoContainer>

      <StyledPageTitle>Sign Up</StyledPageTitle>

      <SignUpForm
        onSignUpSuccessful={handleSignUpSuccessful}
        onUnrecoverableError={handleUnrecoverableError}
      />

      <StyledSignInText>
        Already have an account? <StyledSignInLink href={signInUrl}>Sign in</StyledSignInLink>
      </StyledSignInText>
    </StyledSignUpContainer>
  );
};

SignUpPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(SignUpPage);
