export const PUBLIC_URL = process.env.PUBLIC_URL || "http://localhost:5000";

export const IDP_BASE_URL = process.env.REACT_APP_IDP_BASE_URL || "https://dev-5020997.okta.com";
export const IDP_CLIENT_ID = process.env.REACT_APP_IDP_CLIENT_ID || "0oa5kos5yvMTtavcq5d6";
export const IDP_AUTHORIZE_PATH =
  process.env.REACT_APP_IDP_AUTHORIZE_PATH || "/oauth2/v1/authorize";
export const IDP_LOGOUT_PATH = process.env.REACT_APP_IDP_AUTHORIZE_PATH || "/oauth2/v1/logout";
export const IDP_REDIRECT_PATH = process.env.REACT_APP_IDP_REDIRECT_PATH || "/signin";
export const IDP_POST_LOGOUT_REDIRECT_PATH =
  process.env.REACT_APP_IDP_POST_LOGOUT_REDIRECT_PATH || "/signout";
export const IDP_SCOPE = process.env.REACT_APP_IDP_SCOPE || "openid profile offline_access";

export const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === "true";
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "";
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT || "localhost";
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE || "";

export const SEGMENT_ENABLED = process.env.REACT_APP_SEGMENT_ENABLED === "true";
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY || "";
