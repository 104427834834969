/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import { createTheme, mergeThemes } from "@fluentui/react-northstar";
import { Provider } from "@fluentui/react-northstar/dist/commonjs/components/Provider/Provider";
import { svgIconStyles } from "@fluentui/react-northstar/dist/es/themes/teams/components/SvgIcon/svgIconStyles";
import { svgIconVariables } from "@fluentui/react-northstar/dist/es/themes/teams/components/SvgIcon/svgIconVariables";
import * as siteVariables from "@fluentui/react-northstar/dist/es/themes/teams/siteVariables";
import { ThemeProvider as IntouchThemeProvider } from "@intouchhealth/components/themes/provider";
import { loadTheme } from "office-ui-fabric-react";

import GlobalStyles from "./styles/GlobalStyles";

const iconTheme = {
  componentStyles: {
    SvgIcon: svgIconStyles,
  },
  componentVariables: {
    SvgIcon: svgIconVariables,
  },
  siteVariables,
};

const myTheme = createTheme({
  defaultFontStyle: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    themePrimary: "#4a00d4",
    themeLighterAlt: "#f7f3fd",
    themeLighter: "#ded0f8",
    themeLight: "#c3a9f2",
    themeTertiary: "#8c5ce5",
    themeSecondary: "#5d1ad9",
    themeDarkAlt: "#4300be",
    themeDark: "#3800a1",
    themeDarker: "#290077",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

loadTheme(myTheme);

const ThemeProvider = ({ children }) => (
  <>
    <GlobalStyles />
    <Provider theme={mergeThemes(iconTheme, myTheme)}>
      <IntouchThemeProvider>{children}</IntouchThemeProvider>
    </Provider>
  </>
);

export default ThemeProvider;
