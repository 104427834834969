const SET_USER_PROFILE = "SET_USER_PROFILE";
const CLEAR_USER_DATA = "CLEAR_USER_DATA";
const SET_ACS_TOKEN = "SET_ACS_TOKEN";

const setUserProfile = profile => ({
  type: SET_USER_PROFILE,
  profile,
});

const setAcsToken = token => ({
  type: SET_ACS_TOKEN,
  token,
});

const clearUserData = () => ({
  type: CLEAR_USER_DATA,
});

export {
  SET_USER_PROFILE,
  SET_ACS_TOKEN,
  CLEAR_USER_DATA,
  setUserProfile,
  setAcsToken,
  clearUserData,
};
