import React, { useEffect } from "react";

import qs from "qs";
import { connect } from "react-redux";

import { postSignIn } from "../api/v1/auth";
import SignInSpinner from "../components/SignInSpinner";
import { setUserProfile } from "../store/user/actions";
import { STATE_PARAM, NONCE_PARAM } from "../util/auth";
import { backupUserProfile } from "../util/user";

const SignInCallback = ({ location, dispatch, history }) => {
  const checkStateParam = (queryParams, state) => {
    if (queryParams.state === state) return;

    const error = {
      code: 401,
      message:
        `The state value provided by IdP DOES NOT MATCH the one passed to ` +
        `the authorize endpoint. queryParams.state: ${queryParams.state}, state: ${state}`,
    };

    throw error;
  };

  const getAndRemove = key => {
    const value = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);

    return value;
  };

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    queryParams.nonce = getAndRemove(NONCE_PARAM);
    const state = getAndRemove(STATE_PARAM);
    checkStateParam(queryParams, state);

    postSignIn(queryParams).then(response => {
      dispatch(setUserProfile(response.data));
      backupUserProfile(response.data);
      history.push("/");
    });
  }, [history, location, dispatch]);

  return (
    <div data-testid="sign-in-callback-container">
      <SignInSpinner />
    </div>
  );
};

export default connect()(SignInCallback);
