import React from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { CallVideoIcon } from "@fluentui/react-icons-northstar";
import { Button as IthButton } from "@intouchhealth/components";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { routesPaths } from "../routers/routesPaths";
import { colors } from "../styles/colors";

const StyledDashboardContent = styled.div`
  width: 80%;
  max-width: 46.5rem;
  margin: 0 auto;
  text-align: center;
  color: ${colors.black};
  padding-top: 6rem;
  padding-bottom: 10rem;
`;

const StyledFirstText = styled.p`
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const StyledSecondText = styled.p`
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 2.8125rem;
`;

const StyledCard = styled.div`
  padding: 2.5rem 2rem;
  background-color: ${colors.white};
  border-radius: 28px;
`;

const StyledImageContainer = styled.div`
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 16.875rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 19.0625rem;
`;

const StyledIthButton = styled(IthButton)`
  width: 100%;
  background-color: ${colors.purple};
`;

const DashboardContent = ({ groupId }) => {
  return (
    <StyledDashboardContent>
      <StyledFirstText>Good afternoon</StyledFirstText>
      <StyledSecondText>Get started with your next consultation.</StyledSecondText>
      <StyledCard>
        <StyledImageContainer>
          <StyledImage src="shake_hand.png" alt="Shake hand" />
        </StyledImageContainer>
        <StyledButtonContainer>
          <StyledLink to={`${routesPaths.call}?groupId=${groupId}`}>
            <StyledIthButton
              display="default"
              size="default"
              type="button"
              icon={CallVideoIcon}
              isTextAlignLeft
            >
              Enter Exam Room
            </StyledIthButton>
          </StyledLink>
        </StyledButtonContainer>
      </StyledCard>
    </StyledDashboardContent>
  );
};

export default DashboardContent;
